$(document).ready(function(){

	// WINDOW SIZE

	// call this function to get windowSize any time
	var windowHeight;
	var windowWidth;

	function windowSize() {
	  windowHeight = window.innerHeight ? window.innerHeight : $(window).height();
	  windowWidth = window.innerWidth ? window.innerWidth : $(window).width();
	}

	//windowSize();

	/* VIDEO BG */

    var timeoutId;

    function videobgEnlarge() {

	    var videoBgAspect = $(".videobg-aspect");
	    var videoBgWidth = $(".videobg-width");
	    var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
	    var windowAspect = ($(window).height() / $(window).width());

	    if (windowAspect > videoAspect) {
	        videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
	    } else {
	        videoBgWidth.width(100 + "%")
	    }

    }

    $(window).resize(function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(videobgEnlarge, 100);
    });

    $(function() {
      videobgEnlarge();
    });


    // MENU

	$('.toggleMenu').click(function(){
		$('body').toggleClass('menuOpen');
		$('.mainMenu').toggleClass('active');
		$(this).toggleClass('active');
		$('.submenu').removeClass('active');
	});

	$('div.menu1').click(function(){
		$(this).parent().find('.submenu').addClass('active');
	});

	$('.submenu').find('.backBtn').click(function(){
		$(this).parent().removeClass('active');
	});

	$('.submenu').parent().addClass('submenuActive');


    // Owl carousel home

    // HOME SYNC CAROUSEL

    var sync1 = $("#sync1one");
    var sync2 = $("#sync2one");

    var flag = false;

    var slides = sync1.owlCarousel({
        items: 1,
        nav:false,
        loop: false,
        dots: false,
        autoplay: false,
        pullDrag: false,
        freeDrag: false,
        mouseDrag: false,
        touchDrag: false,
        autoplayHoverPause: false,
    }).on('change.owl.carousel', function(e) {
        if (e.namespace && e.property.name === 'position' && !flag) {
            flag = true; thumbs.to(e.relatedTarget.relative(e.property.value), 300, true);
            flag = false;
        }
    }).data('owl.carousel');

    var thumbs = sync2.owlCarousel({
        items:1,
        nav:false,
        loop: false,
        dots: false,
        pullDrag: false,
        freeDrag: false,
        mouseDrag: false,
        touchDrag: false,
    }).on('click', '.item', function(e) {
        e.preventDefault(); sync1.trigger('to.owl.carousel', [$(e.target).parents('.owl-item').index(), 300, true]);
    }).on('change.owl.carousel', function(e) {
        if (e.namespace && e.property.name === 'position' && !flag) {
            flag = true; slides.to(e.relatedTarget.relative(e.property.value), 300, true);
            flag = false;
        }
    }).data('owl.carousel');



	// Owl carousel home

	// HOME SYNC CAROUSEL

	var sync1 = $("#sync1");
	var sync2 = $("#sync2");

	var flag = false;

	var slides = sync1.owlCarousel({
		items: 1,
		nav:false,
		loop: true,
		dots: false,
		autoplay: true,
		autoplayHoverPause: true,
	}).on('change.owl.carousel', function(e) {
		if (e.namespace && e.property.name === 'position' && !flag) {
			flag = true; thumbs.to(e.relatedTarget.relative(e.property.value), 300, true);
			flag = false;
		}
	}).data('owl.carousel');

	var thumbs = sync2.owlCarousel({
		items:1,
		nav:false,
		loop: true,
		dots: false,
		animateOut: 'fadeOut',
		pullDrag: false,
		freeDrag: false,
		mouseDrag: false,
		touchDrag: false,
	}).on('click', '.item', function(e) {
		e.preventDefault(); sync1.trigger('to.owl.carousel', [$(e.target).parents('.owl-item').index(), 300, true]);
	}).on('change.owl.carousel', function(e) {
		if (e.namespace && e.property.name === 'position' && !flag) {
			flag = true; slides.to(e.relatedTarget.relative(e.property.value), 300, true);
			flag = false;
		}
	}).data('owl.carousel');

  	// Go to the next item
    $('.owlNextBloc').click(function() {
        sync1.trigger('next.owl.carousel');
    })

    // // Go to the previous item
    // $('.owlPrevProject').click(function() {
    //     sync1.trigger('prev.owl.carousel');
    // });


      
	// OWL-carousel Number

	var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-number').each(function(){

     	var newID = 'owl-carousel-number' + IDnumber;

    	$(this).attr('id', newID);
    	IDnumber++;
     
    	var owl = $('#' + newID);


    	owl.owlCarousel({
    		loop:true,
		    nav:false,
		    items: 1,
		    dots: false
    	});

    	owl.trigger('refresh.owl.carousel');

    	$(this).next().addClass('owlNextBloc-number' + NEXTPREVnumber)
    	$(this).next().next().addClass('owlPrevBloc-number' + NEXTPREVnumber)

    	// Go to the next item
	    $('.owlNextBloc-number' + NEXTPREVnumber).click(function() {
	        owl.trigger('next.owl.carousel');
	    })

	    // Go to the previous item
	    $('.owlPrevBloc-number' + NEXTPREVnumber).click(function() {
	        // With optional speed parameter
	        // Parameters has to be in square bracket '[]'
	        owl.trigger('prev.owl.carousel', [300]);
	    });

	    NEXTPREVnumber++;

    });


    	// OWL-carousel Gallery

    	var IDgallery = 1;
        var NEXTPREVgallery = 1;
        var IDblock = 1;

        $('.owl-carousel-miniature').each(function(){

         	var newID = 'owl-carousel-miniature' + IDgallery;

        	$(this).attr('id', newID);
        	IDgallery++;
         
        	var owl = $('#' + newID);


        	owl.owlCarousel({
        		loop:true,
    		    nav:false,
    		    items: 1,
    		    dots: false
        	});

        	owl.trigger('refresh.owl.carousel');

        	$(this).next().addClass('owlNextBloc-miniature' + NEXTPREVgallery)
        	$(this).next().next().addClass('owlPrevBloc-miniature' + NEXTPREVgallery)

        	// Go to the next item
    	    $('.owlNextBloc-miniature' + NEXTPREVgallery).click(function() {
    	        owl.trigger('next.owl.carousel');
    	    })

    	    // Go to the previous item
    	    $('.owlPrevBloc-miniature' + NEXTPREVgallery).click(function() {
    	        // With optional speed parameter
    	        // Parameters has to be in square bracket '[]'
    	        owl.trigger('prev.owl.carousel', [300]);
    	    });

    	    NEXTPREVgallery++;

        });


        // ACTION POUR ALLER A LA BONNE SLIDE (ICI LA SLIDE 3)

        $('.blockMiniature-imgSmall').click(function(){
        	var owl = $(this).parent().parent().find('.owl-carousel-miniature');
        	var owlBlock = $(this).parent().parent().find('.blockMiniature-imgFullContainer');
        	var anchorGallery = $(this).index();
            owl.trigger('to.owl.carousel', [anchorGallery, 0])
            owlBlock.addClass('active');
        });

        $('.closeOwl').click(function(){
        	$('.blockMiniature-imgFullContainer').removeClass('active');
        });	



	// CAROUSEL PARTNERS
	
	var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-partners').each(function(){

     	var newID = 'owl-carousel-partners' + IDnumber;

		$(this).attr('id', newID);
    	IDnumber++;
     
		var owl = $('#' + newID);


    	owl.owlCarousel({
    		loop:false,
		    nav:false,
			responsiveClass:true,
			responsive : {
				0 : {
					items: 1,
				},

				400 : {
					items : 2,
				},

				767 :{
					items : 4,
				},
				// breakpoint 991px
				991 : {
					items : 5, 
				}


			},
		    dots: false
    	});

    	owl.trigger('refresh.owl.carousel');

    	$(this).next().addClass('owlNextBloc-partners' + NEXTPREVnumber)
    	$(this).prev().addClass('owlPrevBloc-partners' + NEXTPREVnumber)

    	// Go to the next item
	    $('.owlNextBloc-partners' + NEXTPREVnumber).click(function() {
	        owl.trigger('next.owl.carousel');
	    })

	    // Go to the previous item
	    $('.owlPrevBloc-partners' + NEXTPREVnumber).click(function() {
	        // With optional speed parameter
	        // Parameters has to be in square bracket '[]'
	        owl.trigger('prev.owl.carousel', [300]);
	    });

	    NEXTPREVnumber++;

    });



    // CAROUSEL HOME FONCTION

    function calcPaddingBottomSliderHome(){
    	windowSize();
    	var heightMaxBloc = 0;

    	$('.itemTest').each(function(){

    		heightBloc = $(this).outerHeight(true);

    		if(heightBloc > heightMaxBloc){
    			heightMaxBloc = heightBloc;
    		}
    	});



    	if( windowWidth <= 575 ){
    		heightMore = heightMaxBloc - 100;
    		if(heightMore > 0){
    			$('.homeSliderContainer').css('padding-bottom', heightMore + 50);
    		} else {
    			$('.homeSliderContainer').css('padding-bottom', 50);
    		}
    	} else if( windowWidth <= 767 ){
    		heightMore = heightMaxBloc - 150;

    		if(heightMore > 0){
    			$('.homeSliderContainer').css('padding-bottom', heightMore + 50);
    		} else {
    			$('.homeSliderContainer').css('padding-bottom', 50);
    		}
    	} else if( windowWidth <= 991 ){
    		heightMore = heightMaxBloc - 340;

    		if(heightMore > 0){
    			$('.homeSliderContainer').css('padding-bottom', heightMore + 50);
    		} else {
    			$('.homeSliderContainer').css('padding-bottom', 50);
    		}
    	} else if( windowWidth <= 1199 ){
    		heightMore = heightMaxBloc - 330;

    		if(heightMore > 0){
    			$('.homeSliderContainer').css('padding-bottom', heightMore + 50);
    		} else {
    			$('.homeSliderContainer').css('padding-bottom', 50);
    		}
    	} else {
    		heightMore = heightMaxBloc - 320;

    		if(heightMore > 0){
    			$('.homeSliderContainer').css('padding-bottom', heightMore + 50);
    		} else {
    			$('.homeSliderContainer').css('padding-bottom', 50);
    		}
    	}
    }

    calcPaddingBottomSliderHome();
    $(window).resize(calcPaddingBottomSliderHome);

    var pageNumber = 1;

    $('.block_listing_actus').on('click', '#loadmore_posts', function (e) {
        $(this).hide();
        $('.loadfirst').hide();        
        pageNumber++;
        var data = '&pageNumber=' + pageNumber;
        $.ajax({
            'url': ajaxurl,
            'type': 'GET',
            'async': true,
            'data': data + '&action=more_posts_ajax',
            success: function (response) {
                var res = JSON.parse(response);
                $('#posts-more').append(res);
            }
        });
    });

    // OWL-carousel slider

    var IDnumber = 1;
    var NEXTPREVnumber = 1;

    $('.owl-carousel-slider').each(function(){

        var newID = 'owl-carousel-slider' + IDnumber;

        $(this).attr('id', newID);
        IDnumber++;
     
        var owl = $('#' + newID);


        owl.owlCarousel({
            loop:true,
            nav:false,
            items: 1,
            dots: false
        });

        owl.trigger('refresh.owl.carousel');

        $(this).next().addClass('owlNextBloc-slider' + NEXTPREVnumber)
        $(this).next().next().addClass('owlPrevBloc-slider' + NEXTPREVnumber)

        // Go to the next item
        $('.owlNextBloc-slider' + NEXTPREVnumber).click(function() {
            owl.trigger('next.owl.carousel');
        })

        // Go to the previous item
        $('.owlPrevBloc-slider' + NEXTPREVnumber).click(function() {
            // With optional speed parameter
            // Parameters has to be in square bracket '[]'
            owl.trigger('prev.owl.carousel', [300]);
        });

        NEXTPREVnumber++;

    });


    if($( ".block" ).last().hasClass('bgGrey')){
        $( ".block" ).last().css( "margin-bottom", "0px" );
    }

    var position = $(window).scrollTop(); 

    // should start at 0

    function headerFix(){
        var topDiv = $('.content').offset().top;
        var window_top = $(window).scrollTop();

        if (window_top > topDiv){
          var scroll = $(window).scrollTop();
          if(scroll > position) {
              $('.headerFix').removeClass('active');
          } else {
               $('.headerFix').addClass('active');
          }
          position = scroll;
        }else{
            $('.headerFix').addClass('active');
        } 
    }

    headerFix();
    $(window).scroll(headerFix);
    $(window).resize(headerFix);

    


});